/*!
 * Tabler (v1.0.0-alpha.4)
 * Copyright 2018-2020 The Tabler Authors
 * Copyright 2018-2020 codecalm
 * Licensed under MIT (https://github.com/tabler/tabler/blob/master/LICENSE)
 */
/**
Dark mode
 */
.btn-ghost-primary {
  color: #206bc4;
  background-color: transparent;
  border-color: transparent; }
  .btn-ghost-primary:hover {
    color: #fff;
    background-color: #206bc4;
    border-color: #206bc4; }
  .btn-ghost-primary:focus, .btn-ghost-primary.focus {
    color: #fff;
    background-color: #206bc4;
    border-color: #206bc4;
    box-shadow: 0 0 0 0.2rem rgba(32, 107, 196, 0.5); }
  .btn-ghost-primary:active, .btn-ghost-primary.active,
  .show > .btn-ghost-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #206bc4;
    border-color: #206bc4; }
    .btn-ghost-primary:active:focus, .btn-ghost-primary.active:focus,
    .show > .btn-ghost-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(32, 107, 196, 0.5); }
  .btn-ghost-primary:disabled, .btn-ghost-primary.disabled {
    color: #206bc4;
    background-color: transparent;
    border-color: transparent; }

.btn-ghost-secondary {
  color: #868c97;
  background-color: transparent;
  border-color: transparent; }
  .btn-ghost-secondary:hover {
    color: #fff;
    background-color: #868c97;
    border-color: #868c97; }
  .btn-ghost-secondary:focus, .btn-ghost-secondary.focus {
    color: #fff;
    background-color: #868c97;
    border-color: #868c97;
    box-shadow: 0 0 0 0.2rem rgba(134, 140, 151, 0.5); }
  .btn-ghost-secondary:active, .btn-ghost-secondary.active,
  .show > .btn-ghost-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #868c97;
    border-color: #868c97; }
    .btn-ghost-secondary:active:focus, .btn-ghost-secondary.active:focus,
    .show > .btn-ghost-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(134, 140, 151, 0.5); }
  .btn-ghost-secondary:disabled, .btn-ghost-secondary.disabled {
    color: #868c97;
    background-color: transparent;
    border-color: transparent; }

.btn-ghost-success {
  color: #5eba00;
  background-color: transparent;
  border-color: transparent; }
  .btn-ghost-success:hover {
    color: #fff;
    background-color: #5eba00;
    border-color: #5eba00; }
  .btn-ghost-success:focus, .btn-ghost-success.focus {
    color: #fff;
    background-color: #5eba00;
    border-color: #5eba00;
    box-shadow: 0 0 0 0.2rem rgba(94, 186, 0, 0.5); }
  .btn-ghost-success:active, .btn-ghost-success.active,
  .show > .btn-ghost-success.dropdown-toggle {
    color: #ffffff;
    background-color: #5eba00;
    border-color: #5eba00; }
    .btn-ghost-success:active:focus, .btn-ghost-success.active:focus,
    .show > .btn-ghost-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(94, 186, 0, 0.5); }
  .btn-ghost-success:disabled, .btn-ghost-success.disabled {
    color: #5eba00;
    background-color: transparent;
    border-color: transparent; }

.btn-ghost-info {
  color: #45aaf2;
  background-color: transparent;
  border-color: transparent; }
  .btn-ghost-info:hover {
    color: #fff;
    background-color: #45aaf2;
    border-color: #45aaf2; }
  .btn-ghost-info:focus, .btn-ghost-info.focus {
    color: #fff;
    background-color: #45aaf2;
    border-color: #45aaf2;
    box-shadow: 0 0 0 0.2rem rgba(69, 170, 242, 0.5); }
  .btn-ghost-info:active, .btn-ghost-info.active,
  .show > .btn-ghost-info.dropdown-toggle {
    color: #ffffff;
    background-color: #45aaf2;
    border-color: #45aaf2; }
    .btn-ghost-info:active:focus, .btn-ghost-info.active:focus,
    .show > .btn-ghost-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(69, 170, 242, 0.5); }
  .btn-ghost-info:disabled, .btn-ghost-info.disabled {
    color: #45aaf2;
    background-color: transparent;
    border-color: transparent; }

.btn-ghost-warning {
  color: #fab005;
  background-color: transparent;
  border-color: transparent; }
  .btn-ghost-warning:hover {
    color: #fff;
    background-color: #fab005;
    border-color: #fab005; }
  .btn-ghost-warning:focus, .btn-ghost-warning.focus {
    color: #fff;
    background-color: #fab005;
    border-color: #fab005;
    box-shadow: 0 0 0 0.2rem rgba(250, 176, 5, 0.5); }
  .btn-ghost-warning:active, .btn-ghost-warning.active,
  .show > .btn-ghost-warning.dropdown-toggle {
    color: #ffffff;
    background-color: #fab005;
    border-color: #fab005; }
    .btn-ghost-warning:active:focus, .btn-ghost-warning.active:focus,
    .show > .btn-ghost-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(250, 176, 5, 0.5); }
  .btn-ghost-warning:disabled, .btn-ghost-warning.disabled {
    color: #fab005;
    background-color: transparent;
    border-color: transparent; }

.btn-ghost-danger {
  color: #fa4654;
  background-color: transparent;
  border-color: transparent; }
  .btn-ghost-danger:hover {
    color: #fff;
    background-color: #fa4654;
    border-color: #fa4654; }
  .btn-ghost-danger:focus, .btn-ghost-danger.focus {
    color: #fff;
    background-color: #fa4654;
    border-color: #fa4654;
    box-shadow: 0 0 0 0.2rem rgba(250, 70, 84, 0.5); }
  .btn-ghost-danger:active, .btn-ghost-danger.active,
  .show > .btn-ghost-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #fa4654;
    border-color: #fa4654; }
    .btn-ghost-danger:active:focus, .btn-ghost-danger.active:focus,
    .show > .btn-ghost-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(250, 70, 84, 0.5); }
  .btn-ghost-danger:disabled, .btn-ghost-danger.disabled {
    color: #fa4654;
    background-color: transparent;
    border-color: transparent; }

.btn-ghost-light {
  color: #f5f7fb;
  background-color: transparent;
  border-color: transparent; }
  .btn-ghost-light:hover {
    color: #fff;
    background-color: #f5f7fb;
    border-color: #f5f7fb; }
  .btn-ghost-light:focus, .btn-ghost-light.focus {
    color: #fff;
    background-color: #f5f7fb;
    border-color: #f5f7fb;
    box-shadow: 0 0 0 0.2rem rgba(245, 247, 251, 0.5); }
  .btn-ghost-light:active, .btn-ghost-light.active,
  .show > .btn-ghost-light.dropdown-toggle {
    color: #354052;
    background-color: #f5f7fb;
    border-color: #f5f7fb; }
    .btn-ghost-light:active:focus, .btn-ghost-light.active:focus,
    .show > .btn-ghost-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 247, 251, 0.5); }
  .btn-ghost-light:disabled, .btn-ghost-light.disabled {
    color: #f5f7fb;
    background-color: transparent;
    border-color: transparent; }

.btn-ghost-dark {
  color: #354052;
  background-color: transparent;
  border-color: transparent; }
  .btn-ghost-dark:hover {
    color: #fff;
    background-color: #354052;
    border-color: #354052; }
  .btn-ghost-dark:focus, .btn-ghost-dark.focus {
    color: #fff;
    background-color: #354052;
    border-color: #354052;
    box-shadow: 0 0 0 0.2rem rgba(53, 64, 82, 0.5); }
  .btn-ghost-dark:active, .btn-ghost-dark.active,
  .show > .btn-ghost-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #354052;
    border-color: #354052; }
    .btn-ghost-dark:active:focus, .btn-ghost-dark.active:focus,
    .show > .btn-ghost-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(53, 64, 82, 0.5); }
  .btn-ghost-dark:disabled, .btn-ghost-dark.disabled {
    color: #354052;
    background-color: transparent;
    border-color: transparent; }

.btn-facebook {
  color: #ffffff;
  background-color: #3b5998;
  border-color: #3b5998; }
  .btn-facebook:hover {
    color: #ffffff;
    background-color: #30497c;
    border-color: #2d4373; }
  .btn-facebook:focus, .btn-facebook.focus {
    color: #ffffff;
    background-color: #30497c;
    border-color: #2d4373;
    box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5); }
  .btn-facebook:active, .btn-facebook.active,
  .show > .btn-facebook.dropdown-toggle {
    color: #ffffff;
    background-color: #2d4373;
    border-color: #293e6a; }
    .btn-facebook:active:focus, .btn-facebook.active:focus,
    .show > .btn-facebook.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5); }
  .btn-facebook:disabled, .btn-facebook.disabled {
    color: #ffffff;
    background-color: #3b5998;
    border-color: #3b5998; }

.btn-outline-facebook {
  color: #3b5998;
  border-color: #3b5998; }
  .btn-outline-facebook:hover {
    color: #3b5998;
    background-color: #3b5998;
    border-color: #3b5998; }
  .btn-outline-facebook:focus, .btn-outline-facebook.focus {
    box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.5); }
  .btn-outline-facebook:active, .btn-outline-facebook.active,
  .show > .btn-outline-facebook.dropdown-toggle {
    color: #ffffff;
    background-color: #3b5998;
    border-color: #3b5998; }
    .btn-outline-facebook:active:focus, .btn-outline-facebook.active:focus,
    .show > .btn-outline-facebook.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.5); }
  .btn-outline-facebook:disabled, .btn-outline-facebook.disabled {
    color: #3b5998;
    background-color: transparent; }

.btn-twitter {
  color: #ffffff;
  background-color: #1da1f2;
  border-color: #1da1f2; }
  .btn-twitter:hover {
    color: #ffffff;
    background-color: #0d8ddc;
    border-color: #0c85d0; }
  .btn-twitter:focus, .btn-twitter.focus {
    color: #ffffff;
    background-color: #0d8ddc;
    border-color: #0c85d0;
    box-shadow: 0 0 0 0.2rem rgba(63, 175, 244, 0.5); }
  .btn-twitter:active, .btn-twitter.active,
  .show > .btn-twitter.dropdown-toggle {
    color: #ffffff;
    background-color: #0c85d0;
    border-color: #0b7ec4; }
    .btn-twitter:active:focus, .btn-twitter.active:focus,
    .show > .btn-twitter.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(63, 175, 244, 0.5); }
  .btn-twitter:disabled, .btn-twitter.disabled {
    color: #ffffff;
    background-color: #1da1f2;
    border-color: #1da1f2; }

.btn-outline-twitter {
  color: #1da1f2;
  border-color: #1da1f2; }
  .btn-outline-twitter:hover {
    color: #1da1f2;
    background-color: #1da1f2;
    border-color: #1da1f2; }
  .btn-outline-twitter:focus, .btn-outline-twitter.focus {
    box-shadow: 0 0 0 0.2rem rgba(29, 161, 242, 0.5); }
  .btn-outline-twitter:active, .btn-outline-twitter.active,
  .show > .btn-outline-twitter.dropdown-toggle {
    color: #ffffff;
    background-color: #1da1f2;
    border-color: #1da1f2; }
    .btn-outline-twitter:active:focus, .btn-outline-twitter.active:focus,
    .show > .btn-outline-twitter.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(29, 161, 242, 0.5); }
  .btn-outline-twitter:disabled, .btn-outline-twitter.disabled {
    color: #1da1f2;
    background-color: transparent; }

.btn-google {
  color: #ffffff;
  background-color: #dc4e41;
  border-color: #dc4e41; }
  .btn-google:hover {
    color: #ffffff;
    background-color: #d03526;
    border-color: #c63224; }
  .btn-google:focus, .btn-google.focus {
    color: #ffffff;
    background-color: #d03526;
    border-color: #c63224;
    box-shadow: 0 0 0 0.2rem rgba(225, 105, 94, 0.5); }
  .btn-google:active, .btn-google.active,
  .show > .btn-google.dropdown-toggle {
    color: #ffffff;
    background-color: #c63224;
    border-color: #bb2f22; }
    .btn-google:active:focus, .btn-google.active:focus,
    .show > .btn-google.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 105, 94, 0.5); }
  .btn-google:disabled, .btn-google.disabled {
    color: #ffffff;
    background-color: #dc4e41;
    border-color: #dc4e41; }

.btn-outline-google {
  color: #dc4e41;
  border-color: #dc4e41; }
  .btn-outline-google:hover {
    color: #dc4e41;
    background-color: #dc4e41;
    border-color: #dc4e41; }
  .btn-outline-google:focus, .btn-outline-google.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 78, 65, 0.5); }
  .btn-outline-google:active, .btn-outline-google.active,
  .show > .btn-outline-google.dropdown-toggle {
    color: #ffffff;
    background-color: #dc4e41;
    border-color: #dc4e41; }
    .btn-outline-google:active:focus, .btn-outline-google.active:focus,
    .show > .btn-outline-google.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 78, 65, 0.5); }
  .btn-outline-google:disabled, .btn-outline-google.disabled {
    color: #dc4e41;
    background-color: transparent; }

.btn-youtube {
  color: #ffffff;
  background-color: #ff0000;
  border-color: #ff0000; }
  .btn-youtube:hover {
    color: #ffffff;
    background-color: #d90000;
    border-color: #cc0000; }
  .btn-youtube:focus, .btn-youtube.focus {
    color: #ffffff;
    background-color: #d90000;
    border-color: #cc0000;
    box-shadow: 0 0 0 0.2rem rgba(255, 38, 38, 0.5); }
  .btn-youtube:active, .btn-youtube.active,
  .show > .btn-youtube.dropdown-toggle {
    color: #ffffff;
    background-color: #cc0000;
    border-color: #bf0000; }
    .btn-youtube:active:focus, .btn-youtube.active:focus,
    .show > .btn-youtube.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 38, 38, 0.5); }
  .btn-youtube:disabled, .btn-youtube.disabled {
    color: #ffffff;
    background-color: #ff0000;
    border-color: #ff0000; }

.btn-outline-youtube {
  color: #ff0000;
  border-color: #ff0000; }
  .btn-outline-youtube:hover {
    color: #ff0000;
    background-color: #ff0000;
    border-color: #ff0000; }
  .btn-outline-youtube:focus, .btn-outline-youtube.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.5); }
  .btn-outline-youtube:active, .btn-outline-youtube.active,
  .show > .btn-outline-youtube.dropdown-toggle {
    color: #ffffff;
    background-color: #ff0000;
    border-color: #ff0000; }
    .btn-outline-youtube:active:focus, .btn-outline-youtube.active:focus,
    .show > .btn-outline-youtube.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.5); }
  .btn-outline-youtube:disabled, .btn-outline-youtube.disabled {
    color: #ff0000;
    background-color: transparent; }

.btn-vimeo {
  color: #ffffff;
  background-color: #1ab7ea;
  border-color: #1ab7ea; }
  .btn-vimeo:hover {
    color: #ffffff;
    background-color: #139ecb;
    border-color: #1295bf; }
  .btn-vimeo:focus, .btn-vimeo.focus {
    color: #ffffff;
    background-color: #139ecb;
    border-color: #1295bf;
    box-shadow: 0 0 0 0.2rem rgba(60, 194, 237, 0.5); }
  .btn-vimeo:active, .btn-vimeo.active,
  .show > .btn-vimeo.dropdown-toggle {
    color: #ffffff;
    background-color: #1295bf;
    border-color: #108cb4; }
    .btn-vimeo:active:focus, .btn-vimeo.active:focus,
    .show > .btn-vimeo.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(60, 194, 237, 0.5); }
  .btn-vimeo:disabled, .btn-vimeo.disabled {
    color: #ffffff;
    background-color: #1ab7ea;
    border-color: #1ab7ea; }

.btn-outline-vimeo {
  color: #1ab7ea;
  border-color: #1ab7ea; }
  .btn-outline-vimeo:hover {
    color: #1ab7ea;
    background-color: #1ab7ea;
    border-color: #1ab7ea; }
  .btn-outline-vimeo:focus, .btn-outline-vimeo.focus {
    box-shadow: 0 0 0 0.2rem rgba(26, 183, 234, 0.5); }
  .btn-outline-vimeo:active, .btn-outline-vimeo.active,
  .show > .btn-outline-vimeo.dropdown-toggle {
    color: #ffffff;
    background-color: #1ab7ea;
    border-color: #1ab7ea; }
    .btn-outline-vimeo:active:focus, .btn-outline-vimeo.active:focus,
    .show > .btn-outline-vimeo.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(26, 183, 234, 0.5); }
  .btn-outline-vimeo:disabled, .btn-outline-vimeo.disabled {
    color: #1ab7ea;
    background-color: transparent; }

.btn-dribbble {
  color: #ffffff;
  background-color: #ea4c89;
  border-color: #ea4c89; }
  .btn-dribbble:hover {
    color: #ffffff;
    background-color: #e62a72;
    border-color: #e51e6b; }
  .btn-dribbble:focus, .btn-dribbble.focus {
    color: #ffffff;
    background-color: #e62a72;
    border-color: #e51e6b;
    box-shadow: 0 0 0 0.2rem rgba(237, 103, 155, 0.5); }
  .btn-dribbble:active, .btn-dribbble.active,
  .show > .btn-dribbble.dropdown-toggle {
    color: #ffffff;
    background-color: #e51e6b;
    border-color: #dc1a65; }
    .btn-dribbble:active:focus, .btn-dribbble.active:focus,
    .show > .btn-dribbble.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(237, 103, 155, 0.5); }
  .btn-dribbble:disabled, .btn-dribbble.disabled {
    color: #ffffff;
    background-color: #ea4c89;
    border-color: #ea4c89; }

.btn-outline-dribbble {
  color: #ea4c89;
  border-color: #ea4c89; }
  .btn-outline-dribbble:hover {
    color: #ea4c89;
    background-color: #ea4c89;
    border-color: #ea4c89; }
  .btn-outline-dribbble:focus, .btn-outline-dribbble.focus {
    box-shadow: 0 0 0 0.2rem rgba(234, 76, 137, 0.5); }
  .btn-outline-dribbble:active, .btn-outline-dribbble.active,
  .show > .btn-outline-dribbble.dropdown-toggle {
    color: #ffffff;
    background-color: #ea4c89;
    border-color: #ea4c89; }
    .btn-outline-dribbble:active:focus, .btn-outline-dribbble.active:focus,
    .show > .btn-outline-dribbble.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(234, 76, 137, 0.5); }
  .btn-outline-dribbble:disabled, .btn-outline-dribbble.disabled {
    color: #ea4c89;
    background-color: transparent; }

.btn-github {
  color: #ffffff;
  background-color: #181717;
  border-color: #181717; }
  .btn-github:hover {
    color: #ffffff;
    background-color: #040404;
    border-color: black; }
  .btn-github:focus, .btn-github.focus {
    color: #ffffff;
    background-color: #040404;
    border-color: black;
    box-shadow: 0 0 0 0.2rem rgba(59, 58, 58, 0.5); }
  .btn-github:active, .btn-github.active,
  .show > .btn-github.dropdown-toggle {
    color: #ffffff;
    background-color: black;
    border-color: black; }
    .btn-github:active:focus, .btn-github.active:focus,
    .show > .btn-github.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(59, 58, 58, 0.5); }
  .btn-github:disabled, .btn-github.disabled {
    color: #ffffff;
    background-color: #181717;
    border-color: #181717; }

.btn-outline-github {
  color: #181717;
  border-color: #181717; }
  .btn-outline-github:hover {
    color: #181717;
    background-color: #181717;
    border-color: #181717; }
  .btn-outline-github:focus, .btn-outline-github.focus {
    box-shadow: 0 0 0 0.2rem rgba(24, 23, 23, 0.5); }
  .btn-outline-github:active, .btn-outline-github.active,
  .show > .btn-outline-github.dropdown-toggle {
    color: #ffffff;
    background-color: #181717;
    border-color: #181717; }
    .btn-outline-github:active:focus, .btn-outline-github.active:focus,
    .show > .btn-outline-github.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(24, 23, 23, 0.5); }
  .btn-outline-github:disabled, .btn-outline-github.disabled {
    color: #181717;
    background-color: transparent; }

.btn-instagram {
  color: #ffffff;
  background-color: #e4405f;
  border-color: #e4405f; }
  .btn-instagram:hover {
    color: #ffffff;
    background-color: #de1f44;
    border-color: #d31e40; }
  .btn-instagram:focus, .btn-instagram.focus {
    color: #ffffff;
    background-color: #de1f44;
    border-color: #d31e40;
    box-shadow: 0 0 0 0.2rem rgba(232, 93, 119, 0.5); }
  .btn-instagram:active, .btn-instagram.active,
  .show > .btn-instagram.dropdown-toggle {
    color: #ffffff;
    background-color: #d31e40;
    border-color: #c81c3d; }
    .btn-instagram:active:focus, .btn-instagram.active:focus,
    .show > .btn-instagram.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(232, 93, 119, 0.5); }
  .btn-instagram:disabled, .btn-instagram.disabled {
    color: #ffffff;
    background-color: #e4405f;
    border-color: #e4405f; }

.btn-outline-instagram {
  color: #e4405f;
  border-color: #e4405f; }
  .btn-outline-instagram:hover {
    color: #e4405f;
    background-color: #e4405f;
    border-color: #e4405f; }
  .btn-outline-instagram:focus, .btn-outline-instagram.focus {
    box-shadow: 0 0 0 0.2rem rgba(228, 64, 95, 0.5); }
  .btn-outline-instagram:active, .btn-outline-instagram.active,
  .show > .btn-outline-instagram.dropdown-toggle {
    color: #ffffff;
    background-color: #e4405f;
    border-color: #e4405f; }
    .btn-outline-instagram:active:focus, .btn-outline-instagram.active:focus,
    .show > .btn-outline-instagram.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(228, 64, 95, 0.5); }
  .btn-outline-instagram:disabled, .btn-outline-instagram.disabled {
    color: #e4405f;
    background-color: transparent; }

.btn-pinterest {
  color: #ffffff;
  background-color: #bd081c;
  border-color: #bd081c; }
  .btn-pinterest:hover {
    color: #ffffff;
    background-color: #980617;
    border-color: #8c0615; }
  .btn-pinterest:focus, .btn-pinterest.focus {
    color: #ffffff;
    background-color: #980617;
    border-color: #8c0615;
    box-shadow: 0 0 0 0.2rem rgba(199, 45, 62, 0.5); }
  .btn-pinterest:active, .btn-pinterest.active,
  .show > .btn-pinterest.dropdown-toggle {
    color: #ffffff;
    background-color: #8c0615;
    border-color: #800513; }
    .btn-pinterest:active:focus, .btn-pinterest.active:focus,
    .show > .btn-pinterest.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(199, 45, 62, 0.5); }
  .btn-pinterest:disabled, .btn-pinterest.disabled {
    color: #ffffff;
    background-color: #bd081c;
    border-color: #bd081c; }

.btn-outline-pinterest {
  color: #bd081c;
  border-color: #bd081c; }
  .btn-outline-pinterest:hover {
    color: #bd081c;
    background-color: #bd081c;
    border-color: #bd081c; }
  .btn-outline-pinterest:focus, .btn-outline-pinterest.focus {
    box-shadow: 0 0 0 0.2rem rgba(189, 8, 28, 0.5); }
  .btn-outline-pinterest:active, .btn-outline-pinterest.active,
  .show > .btn-outline-pinterest.dropdown-toggle {
    color: #ffffff;
    background-color: #bd081c;
    border-color: #bd081c; }
    .btn-outline-pinterest:active:focus, .btn-outline-pinterest.active:focus,
    .show > .btn-outline-pinterest.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(189, 8, 28, 0.5); }
  .btn-outline-pinterest:disabled, .btn-outline-pinterest.disabled {
    color: #bd081c;
    background-color: transparent; }

.btn-vk {
  color: #ffffff;
  background-color: #6383a8;
  border-color: #6383a8; }
  .btn-vk:hover {
    color: #ffffff;
    background-color: #527093;
    border-color: #4d6a8b; }
  .btn-vk:focus, .btn-vk.focus {
    color: #ffffff;
    background-color: #527093;
    border-color: #4d6a8b;
    box-shadow: 0 0 0 0.2rem rgba(122, 150, 181, 0.5); }
  .btn-vk:active, .btn-vk.active,
  .show > .btn-vk.dropdown-toggle {
    color: #ffffff;
    background-color: #4d6a8b;
    border-color: #496482; }
    .btn-vk:active:focus, .btn-vk.active:focus,
    .show > .btn-vk.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(122, 150, 181, 0.5); }
  .btn-vk:disabled, .btn-vk.disabled {
    color: #ffffff;
    background-color: #6383a8;
    border-color: #6383a8; }

.btn-outline-vk {
  color: #6383a8;
  border-color: #6383a8; }
  .btn-outline-vk:hover {
    color: #6383a8;
    background-color: #6383a8;
    border-color: #6383a8; }
  .btn-outline-vk:focus, .btn-outline-vk.focus {
    box-shadow: 0 0 0 0.2rem rgba(99, 131, 168, 0.5); }
  .btn-outline-vk:active, .btn-outline-vk.active,
  .show > .btn-outline-vk.dropdown-toggle {
    color: #ffffff;
    background-color: #6383a8;
    border-color: #6383a8; }
    .btn-outline-vk:active:focus, .btn-outline-vk.active:focus,
    .show > .btn-outline-vk.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(99, 131, 168, 0.5); }
  .btn-outline-vk:disabled, .btn-outline-vk.disabled {
    color: #6383a8;
    background-color: transparent; }

.btn-rss {
  color: #ffffff;
  background-color: #ffa500;
  border-color: #ffa500; }
  .btn-rss:hover {
    color: #ffffff;
    background-color: #d98c00;
    border-color: #cc8400; }
  .btn-rss:focus, .btn-rss.focus {
    color: #ffffff;
    background-color: #d98c00;
    border-color: #cc8400;
    box-shadow: 0 0 0 0.2rem rgba(255, 179, 38, 0.5); }
  .btn-rss:active, .btn-rss.active,
  .show > .btn-rss.dropdown-toggle {
    color: #ffffff;
    background-color: #cc8400;
    border-color: #bf7c00; }
    .btn-rss:active:focus, .btn-rss.active:focus,
    .show > .btn-rss.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 179, 38, 0.5); }
  .btn-rss:disabled, .btn-rss.disabled {
    color: #ffffff;
    background-color: #ffa500;
    border-color: #ffa500; }

.btn-outline-rss {
  color: #ffa500;
  border-color: #ffa500; }
  .btn-outline-rss:hover {
    color: #ffa500;
    background-color: #ffa500;
    border-color: #ffa500; }
  .btn-outline-rss:focus, .btn-outline-rss.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 165, 0, 0.5); }
  .btn-outline-rss:active, .btn-outline-rss.active,
  .show > .btn-outline-rss.dropdown-toggle {
    color: #ffffff;
    background-color: #ffa500;
    border-color: #ffa500; }
    .btn-outline-rss:active:focus, .btn-outline-rss.active:focus,
    .show > .btn-outline-rss.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 165, 0, 0.5); }
  .btn-outline-rss:disabled, .btn-outline-rss.disabled {
    color: #ffa500;
    background-color: transparent; }

.btn-flickr {
  color: #ffffff;
  background-color: #0063dc;
  border-color: #0063dc; }
  .btn-flickr:hover {
    color: #ffffff;
    background-color: #0052b6;
    border-color: #004ca9; }
  .btn-flickr:focus, .btn-flickr.focus {
    color: #ffffff;
    background-color: #0052b6;
    border-color: #004ca9;
    box-shadow: 0 0 0 0.2rem rgba(38, 122, 225, 0.5); }
  .btn-flickr:active, .btn-flickr.active,
  .show > .btn-flickr.dropdown-toggle {
    color: #ffffff;
    background-color: #004ca9;
    border-color: #00469c; }
    .btn-flickr:active:focus, .btn-flickr.active:focus,
    .show > .btn-flickr.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 122, 225, 0.5); }
  .btn-flickr:disabled, .btn-flickr.disabled {
    color: #ffffff;
    background-color: #0063dc;
    border-color: #0063dc; }

.btn-outline-flickr {
  color: #0063dc;
  border-color: #0063dc; }
  .btn-outline-flickr:hover {
    color: #0063dc;
    background-color: #0063dc;
    border-color: #0063dc; }
  .btn-outline-flickr:focus, .btn-outline-flickr.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 99, 220, 0.5); }
  .btn-outline-flickr:active, .btn-outline-flickr.active,
  .show > .btn-outline-flickr.dropdown-toggle {
    color: #ffffff;
    background-color: #0063dc;
    border-color: #0063dc; }
    .btn-outline-flickr:active:focus, .btn-outline-flickr.active:focus,
    .show > .btn-outline-flickr.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 99, 220, 0.5); }
  .btn-outline-flickr:disabled, .btn-outline-flickr.disabled {
    color: #0063dc;
    background-color: transparent; }

.btn-bitbucket {
  color: #ffffff;
  background-color: #0052cc;
  border-color: #0052cc; }
  .btn-bitbucket:hover {
    color: #ffffff;
    background-color: #0043a6;
    border-color: #003e99; }
  .btn-bitbucket:focus, .btn-bitbucket.focus {
    color: #ffffff;
    background-color: #0043a6;
    border-color: #003e99;
    box-shadow: 0 0 0 0.2rem rgba(38, 108, 212, 0.5); }
  .btn-bitbucket:active, .btn-bitbucket.active,
  .show > .btn-bitbucket.dropdown-toggle {
    color: #ffffff;
    background-color: #003e99;
    border-color: #00388c; }
    .btn-bitbucket:active:focus, .btn-bitbucket.active:focus,
    .show > .btn-bitbucket.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 108, 212, 0.5); }
  .btn-bitbucket:disabled, .btn-bitbucket.disabled {
    color: #ffffff;
    background-color: #0052cc;
    border-color: #0052cc; }

.btn-outline-bitbucket {
  color: #0052cc;
  border-color: #0052cc; }
  .btn-outline-bitbucket:hover {
    color: #0052cc;
    background-color: #0052cc;
    border-color: #0052cc; }
  .btn-outline-bitbucket:focus, .btn-outline-bitbucket.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 82, 204, 0.5); }
  .btn-outline-bitbucket:active, .btn-outline-bitbucket.active,
  .show > .btn-outline-bitbucket.dropdown-toggle {
    color: #ffffff;
    background-color: #0052cc;
    border-color: #0052cc; }
    .btn-outline-bitbucket:active:focus, .btn-outline-bitbucket.active:focus,
    .show > .btn-outline-bitbucket.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 82, 204, 0.5); }
  .btn-outline-bitbucket:disabled, .btn-outline-bitbucket.disabled {
    color: #0052cc;
    background-color: transparent; }

.btn-tabler {
  color: #ffffff;
  background-color: #206bc4;
  border-color: #206bc4; }
  .btn-tabler:hover {
    color: #ffffff;
    background-color: #1b59a3;
    border-color: #195398; }
  .btn-tabler:focus, .btn-tabler.focus {
    color: #ffffff;
    background-color: #1b59a3;
    border-color: #195398;
    box-shadow: 0 0 0 0.2rem rgba(65, 129, 205, 0.5); }
  .btn-tabler:active, .btn-tabler.active,
  .show > .btn-tabler.dropdown-toggle {
    color: #ffffff;
    background-color: #195398;
    border-color: #174d8d; }
    .btn-tabler:active:focus, .btn-tabler.active:focus,
    .show > .btn-tabler.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(65, 129, 205, 0.5); }
  .btn-tabler:disabled, .btn-tabler.disabled {
    color: #ffffff;
    background-color: #206bc4;
    border-color: #206bc4; }

.btn-outline-tabler {
  color: #206bc4;
  border-color: #206bc4; }
  .btn-outline-tabler:hover {
    color: #206bc4;
    background-color: #206bc4;
    border-color: #206bc4; }
  .btn-outline-tabler:focus, .btn-outline-tabler.focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 107, 196, 0.5); }
  .btn-outline-tabler:active, .btn-outline-tabler.active,
  .show > .btn-outline-tabler.dropdown-toggle {
    color: #ffffff;
    background-color: #206bc4;
    border-color: #206bc4; }
    .btn-outline-tabler:active:focus, .btn-outline-tabler.active:focus,
    .show > .btn-outline-tabler.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(32, 107, 196, 0.5); }
  .btn-outline-tabler:disabled, .btn-outline-tabler.disabled {
    color: #206bc4;
    background-color: transparent; }

.btn-blue {
  color: #ffffff;
  background-color: #206bc4;
  border-color: #206bc4; }
  .btn-blue:hover {
    color: #ffffff;
    background-color: #1b59a3;
    border-color: #195398; }
  .btn-blue:focus, .btn-blue.focus {
    color: #ffffff;
    background-color: #1b59a3;
    border-color: #195398;
    box-shadow: 0 0 0 0.2rem rgba(65, 129, 205, 0.5); }
  .btn-blue:active, .btn-blue.active,
  .show > .btn-blue.dropdown-toggle {
    color: #ffffff;
    background-color: #195398;
    border-color: #174d8d; }
    .btn-blue:active:focus, .btn-blue.active:focus,
    .show > .btn-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(65, 129, 205, 0.5); }
  .btn-blue:disabled, .btn-blue.disabled {
    color: #ffffff;
    background-color: #206bc4;
    border-color: #206bc4; }

.btn-outline-blue {
  color: #206bc4;
  border-color: #206bc4; }
  .btn-outline-blue:hover {
    color: #206bc4;
    background-color: #206bc4;
    border-color: #206bc4; }
  .btn-outline-blue:focus, .btn-outline-blue.focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 107, 196, 0.5); }
  .btn-outline-blue:active, .btn-outline-blue.active,
  .show > .btn-outline-blue.dropdown-toggle {
    color: #ffffff;
    background-color: #206bc4;
    border-color: #206bc4; }
    .btn-outline-blue:active:focus, .btn-outline-blue.active:focus,
    .show > .btn-outline-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(32, 107, 196, 0.5); }
  .btn-outline-blue:disabled, .btn-outline-blue.disabled {
    color: #206bc4;
    background-color: transparent; }

.btn-azure {
  color: #ffffff;
  background-color: #45aaf2;
  border-color: #45aaf2; }
  .btn-azure:hover {
    color: #ffffff;
    background-color: #219af0;
    border-color: #1594ef; }
  .btn-azure:focus, .btn-azure.focus {
    color: #ffffff;
    background-color: #219af0;
    border-color: #1594ef;
    box-shadow: 0 0 0 0.2rem rgba(97, 183, 244, 0.5); }
  .btn-azure:active, .btn-azure.active,
  .show > .btn-azure.dropdown-toggle {
    color: #ffffff;
    background-color: #1594ef;
    border-color: #108ee7; }
    .btn-azure:active:focus, .btn-azure.active:focus,
    .show > .btn-azure.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(97, 183, 244, 0.5); }
  .btn-azure:disabled, .btn-azure.disabled {
    color: #ffffff;
    background-color: #45aaf2;
    border-color: #45aaf2; }

.btn-outline-azure {
  color: #45aaf2;
  border-color: #45aaf2; }
  .btn-outline-azure:hover {
    color: #45aaf2;
    background-color: #45aaf2;
    border-color: #45aaf2; }
  .btn-outline-azure:focus, .btn-outline-azure.focus {
    box-shadow: 0 0 0 0.2rem rgba(69, 170, 242, 0.5); }
  .btn-outline-azure:active, .btn-outline-azure.active,
  .show > .btn-outline-azure.dropdown-toggle {
    color: #ffffff;
    background-color: #45aaf2;
    border-color: #45aaf2; }
    .btn-outline-azure:active:focus, .btn-outline-azure.active:focus,
    .show > .btn-outline-azure.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(69, 170, 242, 0.5); }
  .btn-outline-azure:disabled, .btn-outline-azure.disabled {
    color: #45aaf2;
    background-color: transparent; }

.btn-indigo {
  color: #ffffff;
  background-color: #6574cd;
  border-color: #6574cd; }
  .btn-indigo:hover {
    color: #ffffff;
    background-color: #485ac4;
    border-color: #3f51c1; }
  .btn-indigo:focus, .btn-indigo.focus {
    color: #ffffff;
    background-color: #485ac4;
    border-color: #3f51c1;
    box-shadow: 0 0 0 0.2rem rgba(124, 137, 213, 0.5); }
  .btn-indigo:active, .btn-indigo.active,
  .show > .btn-indigo.dropdown-toggle {
    color: #ffffff;
    background-color: #3f51c1;
    border-color: #3b4db7; }
    .btn-indigo:active:focus, .btn-indigo.active:focus,
    .show > .btn-indigo.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(124, 137, 213, 0.5); }
  .btn-indigo:disabled, .btn-indigo.disabled {
    color: #ffffff;
    background-color: #6574cd;
    border-color: #6574cd; }

.btn-outline-indigo {
  color: #6574cd;
  border-color: #6574cd; }
  .btn-outline-indigo:hover {
    color: #6574cd;
    background-color: #6574cd;
    border-color: #6574cd; }
  .btn-outline-indigo:focus, .btn-outline-indigo.focus {
    box-shadow: 0 0 0 0.2rem rgba(101, 116, 205, 0.5); }
  .btn-outline-indigo:active, .btn-outline-indigo.active,
  .show > .btn-outline-indigo.dropdown-toggle {
    color: #ffffff;
    background-color: #6574cd;
    border-color: #6574cd; }
    .btn-outline-indigo:active:focus, .btn-outline-indigo.active:focus,
    .show > .btn-outline-indigo.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(101, 116, 205, 0.5); }
  .btn-outline-indigo:disabled, .btn-outline-indigo.disabled {
    color: #6574cd;
    background-color: transparent; }

.btn-purple {
  color: #ffffff;
  background-color: #a55eea;
  border-color: #a55eea; }
  .btn-purple:hover {
    color: #ffffff;
    background-color: #923ce6;
    border-color: #8c31e4; }
  .btn-purple:focus, .btn-purple.focus {
    color: #ffffff;
    background-color: #923ce6;
    border-color: #8c31e4;
    box-shadow: 0 0 0 0.2rem rgba(179, 118, 237, 0.5); }
  .btn-purple:active, .btn-purple.active,
  .show > .btn-purple.dropdown-toggle {
    color: #ffffff;
    background-color: #8c31e4;
    border-color: #8526e3; }
    .btn-purple:active:focus, .btn-purple.active:focus,
    .show > .btn-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(179, 118, 237, 0.5); }
  .btn-purple:disabled, .btn-purple.disabled {
    color: #ffffff;
    background-color: #a55eea;
    border-color: #a55eea; }

.btn-outline-purple {
  color: #a55eea;
  border-color: #a55eea; }
  .btn-outline-purple:hover {
    color: #a55eea;
    background-color: #a55eea;
    border-color: #a55eea; }
  .btn-outline-purple:focus, .btn-outline-purple.focus {
    box-shadow: 0 0 0 0.2rem rgba(165, 94, 234, 0.5); }
  .btn-outline-purple:active, .btn-outline-purple.active,
  .show > .btn-outline-purple.dropdown-toggle {
    color: #ffffff;
    background-color: #a55eea;
    border-color: #a55eea; }
    .btn-outline-purple:active:focus, .btn-outline-purple.active:focus,
    .show > .btn-outline-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(165, 94, 234, 0.5); }
  .btn-outline-purple:disabled, .btn-outline-purple.disabled {
    color: #a55eea;
    background-color: transparent; }

.btn-pink {
  color: #ffffff;
  background-color: #f66d9b;
  border-color: #f66d9b; }
  .btn-pink:hover {
    color: #ffffff;
    background-color: #f44982;
    border-color: #f33d7a; }
  .btn-pink:focus, .btn-pink.focus {
    color: #ffffff;
    background-color: #f44982;
    border-color: #f33d7a;
    box-shadow: 0 0 0 0.2rem rgba(247, 131, 170, 0.5); }
  .btn-pink:active, .btn-pink.active,
  .show > .btn-pink.dropdown-toggle {
    color: #ffffff;
    background-color: #f33d7a;
    border-color: #f23172; }
    .btn-pink:active:focus, .btn-pink.active:focus,
    .show > .btn-pink.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(247, 131, 170, 0.5); }
  .btn-pink:disabled, .btn-pink.disabled {
    color: #ffffff;
    background-color: #f66d9b;
    border-color: #f66d9b; }

.btn-outline-pink {
  color: #f66d9b;
  border-color: #f66d9b; }
  .btn-outline-pink:hover {
    color: #f66d9b;
    background-color: #f66d9b;
    border-color: #f66d9b; }
  .btn-outline-pink:focus, .btn-outline-pink.focus {
    box-shadow: 0 0 0 0.2rem rgba(246, 109, 155, 0.5); }
  .btn-outline-pink:active, .btn-outline-pink.active,
  .show > .btn-outline-pink.dropdown-toggle {
    color: #ffffff;
    background-color: #f66d9b;
    border-color: #f66d9b; }
    .btn-outline-pink:active:focus, .btn-outline-pink.active:focus,
    .show > .btn-outline-pink.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(246, 109, 155, 0.5); }
  .btn-outline-pink:disabled, .btn-outline-pink.disabled {
    color: #f66d9b;
    background-color: transparent; }

.btn-red {
  color: #ffffff;
  background-color: #fa4654;
  border-color: #fa4654; }
  .btn-red:hover {
    color: #ffffff;
    background-color: #f92132;
    border-color: #f91426; }
  .btn-red:focus, .btn-red.focus {
    color: #ffffff;
    background-color: #f92132;
    border-color: #f91426;
    box-shadow: 0 0 0 0.2rem rgba(251, 98, 110, 0.5); }
  .btn-red:active, .btn-red.active,
  .show > .btn-red.dropdown-toggle {
    color: #ffffff;
    background-color: #f91426;
    border-color: #f8081b; }
    .btn-red:active:focus, .btn-red.active:focus,
    .show > .btn-red.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(251, 98, 110, 0.5); }
  .btn-red:disabled, .btn-red.disabled {
    color: #ffffff;
    background-color: #fa4654;
    border-color: #fa4654; }

.btn-outline-red {
  color: #fa4654;
  border-color: #fa4654; }
  .btn-outline-red:hover {
    color: #fa4654;
    background-color: #fa4654;
    border-color: #fa4654; }
  .btn-outline-red:focus, .btn-outline-red.focus {
    box-shadow: 0 0 0 0.2rem rgba(250, 70, 84, 0.5); }
  .btn-outline-red:active, .btn-outline-red.active,
  .show > .btn-outline-red.dropdown-toggle {
    color: #ffffff;
    background-color: #fa4654;
    border-color: #fa4654; }
    .btn-outline-red:active:focus, .btn-outline-red.active:focus,
    .show > .btn-outline-red.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(250, 70, 84, 0.5); }
  .btn-outline-red:disabled, .btn-outline-red.disabled {
    color: #fa4654;
    background-color: transparent; }

.btn-orange {
  color: #ffffff;
  background-color: #ff922b;
  border-color: #ff922b; }
  .btn-orange:hover {
    color: #ffffff;
    background-color: #ff7e05;
    border-color: #f77800; }
  .btn-orange:focus, .btn-orange.focus {
    color: #ffffff;
    background-color: #ff7e05;
    border-color: #f77800;
    box-shadow: 0 0 0 0.2rem rgba(255, 162, 75, 0.5); }
  .btn-orange:active, .btn-orange.active,
  .show > .btn-orange.dropdown-toggle {
    color: #ffffff;
    background-color: #f77800;
    border-color: #ea7200; }
    .btn-orange:active:focus, .btn-orange.active:focus,
    .show > .btn-orange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 162, 75, 0.5); }
  .btn-orange:disabled, .btn-orange.disabled {
    color: #ffffff;
    background-color: #ff922b;
    border-color: #ff922b; }

.btn-outline-orange {
  color: #ff922b;
  border-color: #ff922b; }
  .btn-outline-orange:hover {
    color: #ff922b;
    background-color: #ff922b;
    border-color: #ff922b; }
  .btn-outline-orange:focus, .btn-outline-orange.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 146, 43, 0.5); }
  .btn-outline-orange:active, .btn-outline-orange.active,
  .show > .btn-outline-orange.dropdown-toggle {
    color: #ffffff;
    background-color: #ff922b;
    border-color: #ff922b; }
    .btn-outline-orange:active:focus, .btn-outline-orange.active:focus,
    .show > .btn-outline-orange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 146, 43, 0.5); }
  .btn-outline-orange:disabled, .btn-outline-orange.disabled {
    color: #ff922b;
    background-color: transparent; }

.btn-yellow {
  color: #ffffff;
  background-color: #fab005;
  border-color: #fab005; }
  .btn-yellow:hover {
    color: #ffffff;
    background-color: #d59604;
    border-color: #c88d04; }
  .btn-yellow:focus, .btn-yellow.focus {
    color: #ffffff;
    background-color: #d59604;
    border-color: #c88d04;
    box-shadow: 0 0 0 0.2rem rgba(251, 188, 43, 0.5); }
  .btn-yellow:active, .btn-yellow.active,
  .show > .btn-yellow.dropdown-toggle {
    color: #ffffff;
    background-color: #c88d04;
    border-color: #bc8404; }
    .btn-yellow:active:focus, .btn-yellow.active:focus,
    .show > .btn-yellow.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(251, 188, 43, 0.5); }
  .btn-yellow:disabled, .btn-yellow.disabled {
    color: #ffffff;
    background-color: #fab005;
    border-color: #fab005; }

.btn-outline-yellow {
  color: #fab005;
  border-color: #fab005; }
  .btn-outline-yellow:hover {
    color: #fab005;
    background-color: #fab005;
    border-color: #fab005; }
  .btn-outline-yellow:focus, .btn-outline-yellow.focus {
    box-shadow: 0 0 0 0.2rem rgba(250, 176, 5, 0.5); }
  .btn-outline-yellow:active, .btn-outline-yellow.active,
  .show > .btn-outline-yellow.dropdown-toggle {
    color: #ffffff;
    background-color: #fab005;
    border-color: #fab005; }
    .btn-outline-yellow:active:focus, .btn-outline-yellow.active:focus,
    .show > .btn-outline-yellow.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(250, 176, 5, 0.5); }
  .btn-outline-yellow:disabled, .btn-outline-yellow.disabled {
    color: #fab005;
    background-color: transparent; }

.btn-lime {
  color: #ffffff;
  background-color: #94d82d;
  border-color: #94d82d; }
  .btn-lime:hover {
    color: #ffffff;
    background-color: #7fbc23;
    border-color: #78b121; }
  .btn-lime:focus, .btn-lime.focus {
    color: #ffffff;
    background-color: #7fbc23;
    border-color: #78b121;
    box-shadow: 0 0 0 0.2rem rgba(164, 222, 77, 0.5); }
  .btn-lime:active, .btn-lime.active,
  .show > .btn-lime.dropdown-toggle {
    color: #ffffff;
    background-color: #78b121;
    border-color: #70a61f; }
    .btn-lime:active:focus, .btn-lime.active:focus,
    .show > .btn-lime.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(164, 222, 77, 0.5); }
  .btn-lime:disabled, .btn-lime.disabled {
    color: #ffffff;
    background-color: #94d82d;
    border-color: #94d82d; }

.btn-outline-lime {
  color: #94d82d;
  border-color: #94d82d; }
  .btn-outline-lime:hover {
    color: #94d82d;
    background-color: #94d82d;
    border-color: #94d82d; }
  .btn-outline-lime:focus, .btn-outline-lime.focus {
    box-shadow: 0 0 0 0.2rem rgba(148, 216, 45, 0.5); }
  .btn-outline-lime:active, .btn-outline-lime.active,
  .show > .btn-outline-lime.dropdown-toggle {
    color: #ffffff;
    background-color: #94d82d;
    border-color: #94d82d; }
    .btn-outline-lime:active:focus, .btn-outline-lime.active:focus,
    .show > .btn-outline-lime.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(148, 216, 45, 0.5); }
  .btn-outline-lime:disabled, .btn-outline-lime.disabled {
    color: #94d82d;
    background-color: transparent; }

.btn-green {
  color: #ffffff;
  background-color: #5eba00;
  border-color: #5eba00; }
  .btn-green:hover {
    color: #ffffff;
    background-color: #4b9400;
    border-color: #448700; }
  .btn-green:focus, .btn-green.focus {
    color: #ffffff;
    background-color: #4b9400;
    border-color: #448700;
    box-shadow: 0 0 0 0.2rem rgba(118, 196, 38, 0.5); }
  .btn-green:active, .btn-green.active,
  .show > .btn-green.dropdown-toggle {
    color: #ffffff;
    background-color: #448700;
    border-color: #3e7a00; }
    .btn-green:active:focus, .btn-green.active:focus,
    .show > .btn-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(118, 196, 38, 0.5); }
  .btn-green:disabled, .btn-green.disabled {
    color: #ffffff;
    background-color: #5eba00;
    border-color: #5eba00; }

.btn-outline-green {
  color: #5eba00;
  border-color: #5eba00; }
  .btn-outline-green:hover {
    color: #5eba00;
    background-color: #5eba00;
    border-color: #5eba00; }
  .btn-outline-green:focus, .btn-outline-green.focus {
    box-shadow: 0 0 0 0.2rem rgba(94, 186, 0, 0.5); }
  .btn-outline-green:active, .btn-outline-green.active,
  .show > .btn-outline-green.dropdown-toggle {
    color: #ffffff;
    background-color: #5eba00;
    border-color: #5eba00; }
    .btn-outline-green:active:focus, .btn-outline-green.active:focus,
    .show > .btn-outline-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(94, 186, 0, 0.5); }
  .btn-outline-green:disabled, .btn-outline-green.disabled {
    color: #5eba00;
    background-color: transparent; }

.btn-teal {
  color: #ffffff;
  background-color: #2bcbba;
  border-color: #2bcbba; }
  .btn-teal:hover {
    color: #ffffff;
    background-color: #24ab9d;
    border-color: #22a193; }
  .btn-teal:focus, .btn-teal.focus {
    color: #ffffff;
    background-color: #24ab9d;
    border-color: #22a193;
    box-shadow: 0 0 0 0.2rem rgba(75, 211, 196, 0.5); }
  .btn-teal:active, .btn-teal.active,
  .show > .btn-teal.dropdown-toggle {
    color: #ffffff;
    background-color: #22a193;
    border-color: #20968a; }
    .btn-teal:active:focus, .btn-teal.active:focus,
    .show > .btn-teal.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(75, 211, 196, 0.5); }
  .btn-teal:disabled, .btn-teal.disabled {
    color: #ffffff;
    background-color: #2bcbba;
    border-color: #2bcbba; }

.btn-outline-teal {
  color: #2bcbba;
  border-color: #2bcbba; }
  .btn-outline-teal:hover {
    color: #2bcbba;
    background-color: #2bcbba;
    border-color: #2bcbba; }
  .btn-outline-teal:focus, .btn-outline-teal.focus {
    box-shadow: 0 0 0 0.2rem rgba(43, 203, 186, 0.5); }
  .btn-outline-teal:active, .btn-outline-teal.active,
  .show > .btn-outline-teal.dropdown-toggle {
    color: #ffffff;
    background-color: #2bcbba;
    border-color: #2bcbba; }
    .btn-outline-teal:active:focus, .btn-outline-teal.active:focus,
    .show > .btn-outline-teal.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(43, 203, 186, 0.5); }
  .btn-outline-teal:disabled, .btn-outline-teal.disabled {
    color: #2bcbba;
    background-color: transparent; }

.btn-cyan {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-cyan:hover {
    color: #ffffff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-cyan:focus, .btn-cyan.focus {
    color: #ffffff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-cyan:active, .btn-cyan.active,
  .show > .btn-cyan.dropdown-toggle {
    color: #ffffff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-cyan:active:focus, .btn-cyan.active:focus,
    .show > .btn-cyan.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-cyan:disabled, .btn-cyan.disabled {
    color: #ffffff;
    background-color: #17a2b8;
    border-color: #17a2b8; }

.btn-outline-cyan {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-cyan:hover {
    color: #17a2b8;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-cyan:focus, .btn-outline-cyan.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-cyan:active, .btn-outline-cyan.active,
  .show > .btn-outline-cyan.dropdown-toggle {
    color: #ffffff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-cyan:active:focus, .btn-outline-cyan.active:focus,
    .show > .btn-outline-cyan.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-cyan:disabled, .btn-outline-cyan.disabled {
    color: #17a2b8;
    background-color: transparent; }

.btn-white {
  color: #354052;
  background-color: #ffffff;
  border-color: #ffffff; }
  .btn-white:hover {
    color: #354052;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-white:focus, .btn-white.focus {
    color: #354052;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 0 0.2rem rgba(225, 226, 229, 0.5); }
  .btn-white:active, .btn-white.active,
  .show > .btn-white.dropdown-toggle {
    color: #354052;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-white:active:focus, .btn-white.active:focus,
    .show > .btn-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 226, 229, 0.5); }
  .btn-white:disabled, .btn-white.disabled {
    color: #354052;
    background-color: #ffffff;
    border-color: #ffffff; }

.btn-outline-white {
  color: #ffffff;
  border-color: #ffffff; }
  .btn-outline-white:hover {
    color: #ffffff;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-outline-white:focus, .btn-outline-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-white:active, .btn-outline-white.active,
  .show > .btn-outline-white.dropdown-toggle {
    color: #354052;
    background-color: #ffffff;
    border-color: #ffffff; }
    .btn-outline-white:active:focus, .btn-outline-white.active:focus,
    .show > .btn-outline-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-white:disabled, .btn-outline-white.disabled {
    color: #ffffff;
    background-color: transparent; }

.btn-gray {
  color: #ffffff;
  background-color: #a8aeb7;
  border-color: #a8aeb7; }
  .btn-gray:hover {
    color: #ffffff;
    background-color: #939ba6;
    border-color: #8c94a0; }
  .btn-gray:focus, .btn-gray.focus {
    color: #ffffff;
    background-color: #939ba6;
    border-color: #8c94a0;
    box-shadow: 0 0 0 0.2rem rgba(181, 186, 194, 0.5); }
  .btn-gray:active, .btn-gray.active,
  .show > .btn-gray.dropdown-toggle {
    color: #ffffff;
    background-color: #8c94a0;
    border-color: #858e9a; }
    .btn-gray:active:focus, .btn-gray.active:focus,
    .show > .btn-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(181, 186, 194, 0.5); }
  .btn-gray:disabled, .btn-gray.disabled {
    color: #ffffff;
    background-color: #a8aeb7;
    border-color: #a8aeb7; }

.btn-outline-gray {
  color: #a8aeb7;
  border-color: #a8aeb7; }
  .btn-outline-gray:hover {
    color: #a8aeb7;
    background-color: #a8aeb7;
    border-color: #a8aeb7; }
  .btn-outline-gray:focus, .btn-outline-gray.focus {
    box-shadow: 0 0 0 0.2rem rgba(168, 174, 183, 0.5); }
  .btn-outline-gray:active, .btn-outline-gray.active,
  .show > .btn-outline-gray.dropdown-toggle {
    color: #ffffff;
    background-color: #a8aeb7;
    border-color: #a8aeb7; }
    .btn-outline-gray:active:focus, .btn-outline-gray.active:focus,
    .show > .btn-outline-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(168, 174, 183, 0.5); }
  .btn-outline-gray:disabled, .btn-outline-gray.disabled {
    color: #a8aeb7;
    background-color: transparent; }

.btn-gray-dark {
  color: #ffffff;
  background-color: #545d6d;
  border-color: #545d6d; }
  .btn-gray-dark:hover {
    color: #ffffff;
    background-color: #434b57;
    border-color: #3e4450; }
  .btn-gray-dark:focus, .btn-gray-dark.focus {
    color: #ffffff;
    background-color: #434b57;
    border-color: #3e4450;
    box-shadow: 0 0 0 0.2rem rgba(110, 117, 131, 0.5); }
  .btn-gray-dark:active, .btn-gray-dark.active,
  .show > .btn-gray-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #3e4450;
    border-color: #383e49; }
    .btn-gray-dark:active:focus, .btn-gray-dark.active:focus,
    .show > .btn-gray-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(110, 117, 131, 0.5); }
  .btn-gray-dark:disabled, .btn-gray-dark.disabled {
    color: #ffffff;
    background-color: #545d6d;
    border-color: #545d6d; }

.btn-outline-gray-dark {
  color: #545d6d;
  border-color: #545d6d; }
  .btn-outline-gray-dark:hover {
    color: #545d6d;
    background-color: #545d6d;
    border-color: #545d6d; }
  .btn-outline-gray-dark:focus, .btn-outline-gray-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(84, 93, 109, 0.5); }
  .btn-outline-gray-dark:active, .btn-outline-gray-dark.active,
  .show > .btn-outline-gray-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #545d6d;
    border-color: #545d6d; }
    .btn-outline-gray-dark:active:focus, .btn-outline-gray-dark.active:focus,
    .show > .btn-outline-gray-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(84, 93, 109, 0.5); }
  .btn-outline-gray-dark:disabled, .btn-outline-gray-dark.disabled {
    color: #545d6d;
    background-color: transparent; }

.btn-dark {
  color: #ffffff;
  background-color: #354052;
  border-color: #354052; }
  .btn-dark:hover {
    color: #ffffff;
    background-color: #262e3b;
    border-color: #212833; }
  .btn-dark:focus, .btn-dark.focus {
    color: #ffffff;
    background-color: #262e3b;
    border-color: #212833;
    box-shadow: 0 0 0 0.2rem rgba(83, 93, 108, 0.5); }
  .btn-dark:active, .btn-dark.active,
  .show > .btn-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #212833;
    border-color: #1c222b; }
    .btn-dark:active:focus, .btn-dark.active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(83, 93, 108, 0.5); }
  .btn-dark:disabled, .btn-dark.disabled {
    color: #ffffff;
    background-color: #354052;
    border-color: #354052; }

.btn-outline-dark {
  color: #354052;
  border-color: #354052; }
  .btn-outline-dark:hover {
    color: #354052;
    background-color: #354052;
    border-color: #354052; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(53, 64, 82, 0.5); }
  .btn-outline-dark:active, .btn-outline-dark.active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #354052;
    border-color: #354052; }
    .btn-outline-dark:active:focus, .btn-outline-dark.active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(53, 64, 82, 0.5); }
  .btn-outline-dark:disabled, .btn-outline-dark.disabled {
    color: #354052;
    background-color: transparent; }
/*# sourceMappingURL=tabler-buttons.css.map */